import { render, staticRenderFns } from "./NextButton.vue?vue&type=template&id=620f0c57&scoped=true&"
import script from "./NextButton.vue?vue&type=script&lang=js&"
export * from "./NextButton.vue?vue&type=script&lang=js&"
import style0 from "./NextButton.vue?vue&type=style&index=0&id=620f0c57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620f0c57",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VContainer,VIcon,VScaleTransition,VSpacer})
