<template>
    <div class="grey--text text--darken-4">
        <matrix-validation-message v-if="validationMessage" :validationMessage="validationMessage"></matrix-validation-message>
        <div class="table-wrapper">
            <table :light="true" v-if="activeStep.properties" class="matrix-table">
                <thead>
                    <tr>
                        <th class="matrix-title">
                            <b>{{ activeStep.properties.table_title }}</b>
                        </th>
                        <th
                            v-for="col in activeStep.properties.columns"
                            :key="col.id"
                        >
                            <span v-if="!col.optional" class="error--text">*</span>
                            {{ col.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in rows" :key="row.id">
                        <td 
                            :style="{'min-width': activeStep.properties.row_headers_width + 'px !important'}"
                            class="matrix-title">
                            <span v-if="!activeStep.is_optional" class="error--text"
                                >*</span
                            >
                            {{ row.title }}
                        </td>
                        <matrix-cell
                            v-for="col in activeStep.properties.columns"
                            :key="`cell_${row.id}_${col.id}`"
                            :row="row"
                            :col="col"
                            @update:answer="handleRadioChange"
                            :answer="answer"
                        ></matrix-cell>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import MatrixCell from "./MatrixCell.vue";
import { mapGetters } from "vuex";
import MatrixValidationMessage from './MatrixValidationMessage.vue';


const DEFAULT_RADIO_GROUP_NAME = "default_radio";
const DEFAULT_CHECKBOX_GROUP_NAME = "default_checkbox";

export default {
    name: "Matrix",
    components: { MatrixCell, MatrixValidationMessage },
    props: {
        value: {
            required: true
        },
        activeStep: {
            required: true
        },
        survey: {
            required: true
        },
        dark: {
            required: true
        }
    },
    data() {
        return {
            DEFAULT_CHECKBOX_GROUP_NAME,
            DEFAULT_RADIO_GROUP_NAME
        };
    },
    computed: {
        ...mapGetters({
            answers: 'form/answers',
        }),
        validationMessage(){
            if(this.answers && this.answers[this.activeStep.id] && this.activeStep?.properties?.matrix_validation_message !== false){
                return this.answers[this.activeStep.id].valid
            }
            return null
        },
        rows() {
            if(this.activeStep.properties.randomize_rows){
                return this.shuffleArray(this.activeStep.properties.rows);
            }
            return this.activeStep.properties.rows;
        },
        answer: {
            get() {
                return this.value ? this.value.value : null;
            },
            set(value) {
                const valid = this.validate(value);
                this.$emit(
                    "input",
                    value
                        ? {
                              answer: {
                                  type: "matrix",
                                  value
                              },
                              valid
                          }
                        : null
                );
            }
        }
    },
    methods: {
        // Fisher-Yates shuffle algorithm
        shuffleArray(array) {
            const newArray = array.slice(); // Create a copy of the array
            for (let i = newArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
            }
            return newArray;
        },
        handleRadioChange({
            row_id,
            row_title,
            col_id,
            value,
            type,
            group_name,
            col_title,
            valid = true
        }) {
            // Create a new object for the updated state
            let updatedAnswer = { ...this.answer };

            if (!updatedAnswer[row_id]) {
                updatedAnswer[row_id] = {};
            }

            if (type === "radio") { 
                const currentSelection = updatedAnswer[row_id][group_name];
                if (currentSelection && currentSelection.col_id === col_id) {
                    // If the current radio button is already selected, remove the selection
                    delete updatedAnswer[row_id][group_name];
                } else {
                    // Otherwise, update the selection
                    updatedAnswer[row_id][group_name] = { col_id, value, type, row_title, col_title };
                }
            }

            if (type === "checkbox") {
                const currentSelections =
                    updatedAnswer[row_id][group_name] || [];
                const currentSelection = currentSelections.find(
                    selection => selection.col_id === col_id
                );
                if (currentSelection) {
                    // If the current radio button is already selected, remove the selection
                    const newSelections = currentSelections.filter(
                        selection => selection.col_id !== col_id
                    );

                    if (newSelections.length === 0) {
                        // If the array is empty, remove the property
                        delete updatedAnswer[row_id][group_name];
                    } else {
                        // Otherwise, update the selection
                        updatedAnswer[row_id][group_name] = newSelections;
                    }
                } else {
                    // Otherwise, update the selection
                    updatedAnswer[row_id][group_name] = [
                        ...currentSelections,
                        { col_id, value, type, row_title, col_title }
                    ];
                }
            }

            if (type === "text" || type === "numeric" || type === "dropdown") {
                if (value !== null && value !== undefined) {
                    updatedAnswer[row_id][col_id] = {
                        col_id,
                        col_title,
                        value,
                        type,
                        valid,
                        row_title
                    };
                } else {
                    delete updatedAnswer[row_id][col_id];
                }
            }

            // delete the row if it is empty
            if (Object.keys(updatedAnswer[row_id]).length === 0) {
                delete updatedAnswer[row_id];
            }

            // delete the answer if it is empty
            if (Object.keys(updatedAnswer).length === 0) {
                updatedAnswer = null;
            }

            // Update the whole answer object at once
            this.answer = updatedAnswer;
        },
        validate(answer) {
            if(answer){
                const rows = this.rows;
                const cols = this.activeStep.properties.columns;
                const isOptional = !!this.activeStep.is_optional;
    
                for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
                    const row = rows[rowIdx];
    
                    const rowTitle = '"'+(row.title || `${this.$t('form.matrixRow')} ${rowIdx + 1}`)+'"';
                    if (!answer[row.id]) {
                        if (!isOptional) {
                            return this.$t('form.matrixRowRequired', { rowTitle });
                        } else {
                            continue;
                        }
                    } else {
                        for (let colIdx = 0; colIdx < cols.length; colIdx++) {
                            const col = cols[colIdx];
                            
                            const columnTitle = '"'+(col.title || `${this.$t('form.matrixColumn')} ${colIdx + 1}`)+'"';
                            // check group properties
                            if (["radio", "checkbox"].includes(col.type)) {
                                const type = col.type === "radio" ? this.$t('form.matrixRadioGroup') : this.$t('form.matrixCheckboxGroup');
                                const group_name = col.group_name || (col.type === 'radio' ? DEFAULT_RADIO_GROUP_NAME : DEFAULT_CHECKBOX_GROUP_NAME);
                                if (!answer[row.id][group_name]) {
                                    if (!col.optional) {
                                        const groupTitle = col.group_name? `"${col.group_name} "` : `${group_name}`;
                                        return this.$t('form.matrixGroupRequired', { rowTitle, type, groupTitle });
                                    } else {
                                        continue;
                                    }
                                }
                            } else {
                                if (!answer[row.id][col.id]) {
                                    if (!col.optional) {
                                        return this.$t('form.matrixColumnRequired', { rowTitle, columnTitle });
                                    } else {
                                        continue;
                                    }
                                } else {
                                    if (answer[row.id][col.id].valid !== true) {
                                        return this.$t('form.matrixColumnInvalid', { rowTitle, columnTitle });
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
    max-width: calc(100vw - 24px);
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 4px;
    position: relative;
    color: black !important;
}
.matrix-table {
    border-collapse: collapse;
    background-color: white;
    color: black;
}
.matrix-table th,
.matrix-table td {
    border: 1px solid var(--v-grey-lighten3);
    padding: 8px;
    text-align: left;
    min-width: 100px;
    word-break: break-word;
    position: relative;
}
.matrix-table th {
    background-color: var(--v-grey-lighten4);
    font-weight: 500;
    font-size: 14px;
}
.matrix-table .matrix-title:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white; /* Or any color that matches your design */
    border-collapse: separate;
    background-color: var(--v-grey-lighten4);
    font-weight: 500;
    font-size: 14px;

    &:after {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-right: 1px solid var(--v-grey-lighten3);
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
